import React from "react";
import styled from "styled-components";
import { lengths } from "../../values";
import Container from "../Container";

type PushedBoxProps = {
  src: string;
  reversed?: boolean;
};

const PushedBox = styled.div<PushedBoxProps>`
  background-image: url("${(props) => props.src}");
  background-size: min(50rem, 45vw) auto;
  background-repeat: no-repeat;
  background-position: ${(props) => (props.reversed ? "top max(calc(20rem - 18vw), 3rem) left 0" : "top max(calc(20rem - 18vw), 3rem) right 0")};

  padding: 10.5rem 0 14rem 0;

  min-height: 50rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    background: none;

    padding: 4rem 0 5rem 0;

    min-height: 0;
  }
`;

type ContentWrapperProps = {
  reversed?: boolean;
};

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${(props) => (props.reversed ? "end" : "start")};
`;

const ContentBox = styled.div`
  width: 55%;
  max-width: 40rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    width: 100%;
    max-width: 100%;
  }
`;

type Props = React.PropsWithChildren<{
  imageUrl: string;
  reversed?: boolean;
}>;

export default function Pushed(props: Props) {
  return (
    <PushedBox src={props.imageUrl} reversed={props.reversed}>
      <Container>
        <ContentWrapper reversed={props.reversed}>
          <ContentBox>{props.children}</ContentBox>
        </ContentWrapper>
      </Container>
    </PushedBox>
  );
}
