import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { get } from "../utils/data";
import { colors, lengths } from "../values";
import Button from "./Button";
import ViewportSpinner from "./ViewportSpinner";

const NotFoundBox = styled.div`
  margin: 0;
  padding-top: 16.5rem;
  padding-bottom: 16.8rem;
  width: 100%;
`;

const Text = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.875rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: 2rem 1rem;
`;

const ButtonBox = styled.div`
  flex: 0 0 auto;
`;

type Props = {};

export default function NotFound(props: Props) {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState<Record<string, string>>({});

  useEffect(() => {
    setLoading(true);
    get("redirect").then((value) => {
      setRedirect(value);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <ViewportSpinner />;
  } else if (redirect[window.location.pathname]) {
    document.location.replace(redirect[window.location.pathname]);
    return <ViewportSpinner />;
  } else {
    return (
      <NotFoundBox>
        <Text>요청하신 페이지를 찾을 수 없습니다.</Text>
        <ButtonWrapper>
          <ButtonBox>
            <Button to="/">메인 페이지로 가기</Button>
          </ButtonBox>
        </ButtonWrapper>
      </NotFoundBox>
    );
  }
}
