import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, lengths } from "../values";

type ButtonBoxProps = {
  fixedWidth?: boolean;
};

const ButtonBox = styled.a<ButtonBoxProps>`
  display: inline-block;
  background-color: ${colors.blue};
  border-radius: 5px;

  padding: 1rem 1.5rem;

  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  color: ${colors.white};

  min-width: ${(props) => (props.fixedWidth ? "13.5rem" : "0")};

  cursor: pointer;

  transition: all 0.5s ease;

  &:hover {
    background-color: ${colors.darkBlue};
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding: 0.6rem 1.4rem;

    font-size: 0.8125rem;

    min-width: ${(props) => (props.fixedWidth ? "10rem" : "0")};
  }
`;

type Props = React.PropsWithChildren<{
  to?: React.ComponentProps<typeof Link>["to"];
  href?: React.HTMLProps<HTMLAnchorElement>["href"];
  onClick?: React.HTMLProps<HTMLAnchorElement>["onClick"];
  target?: React.HTMLProps<HTMLAnchorElement>["target"];
  fixedWidth?: boolean;
}>;

export default function Button(props: Props) {
  if (props.to) {
    return (
      <ButtonBox as={Link} to={props.to} onClick={props.onClick} target={props.target} fixedWidth={props.fixedWidth}>
        {props.children}
      </ButtonBox>
    );
  } else {
    return (
      <ButtonBox href={props.href} onClick={props.onClick} target={props.target} fixedWidth={props.fixedWidth}>
        {props.children}
      </ButtonBox>
    );
  }
}
