import React from "react";
import { Params, useParams } from "react-router-dom";
import styled from "styled-components";
import { PageInfo } from "../utils/data";
import NotionPageContainer from "./NotionPageContainer";

const Container = styled.div`
  margin: 0;

  width: 100%;
  min-height: 100vh;
  padding-top: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HiringContainer = styled.div`
  max-width: 864px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 6rem;
`;

const hiringItems: { [x: string]: PageInfo } = {
  ml: {
    title: "머신러닝 엔지니어 (ML Engineer)",
    id: "7ee46e12b45949f6b59d84c3595d4e4e",
  },
  backend: {
    title: "백엔드 개발자 (Back-end Developer)",
    id: "c62e3d69ca9c461d8055625e7d44936a",
  },
  frontend: {
    title: "프론트엔드 개발자 (Front-end Developer)",
    id: "34a13dbacd384062875bd984f0d4b196",
  },
};

export default function HiringDetail() {
  const params = useParams<Params>();
  const infoData = hiringItems.hasOwnProperty(params.hiringId as string) ? hiringItems[params.hiringId as string] : void 0;

  return (
    <Container>
      <HiringContainer>
        <NotionPageContainer pageInfo={infoData} />
      </HiringContainer>
    </Container>
  );
}
