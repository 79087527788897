import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Awards from "./landing/Awards";
import Contact from "./landing/Contact";
import Hero from "./landing/Hero";
import Hiring from "./landing/Hiring";
import IconList from "./landing/IconList";
import Partners from "./landing/Partners";
import Product from "./landing/Product";
// import ProductButton from "./landing/ProductButton";
import Pushed from "./landing/Pushed";
import Services from "./landing/Services";

const LandingBox = styled.div`
  margin: 0;

  width: 100%;
`;

export default function Landing() {
  return (
    <LandingBox>
      <Hero title="부동산 AI 솔루션 기업, 탱커" subTitle={"부동산 공공데이터에 독자적인 AI 기술을 더한\n부동산 통합 솔루션을 제공합니다."}>
        <Button fixedWidth href="/link/brochure" target="_blank">
          회사 소개서 다운로드
        </Button>
      </Hero>
      <Partners title="탱커의 주주 구성" imageUrls={["/images/partner_ibk2.png", "/images/partner_mirae_asset2.png"]} />
      <Services
        title="탱커의 서비스"
        subTitle={"국내 최고의 부동산 데이터 파이프라인을 구축하여\n누구나 쉽게 부동산 정보를 활용할 수 있는 부동산 시장을 꿈꿉니다."}
        services={[
          {
            title: "블리츠 Blitz",
            body: "주소 입력 한 번으로 부동산 가치가\n자동으로 산정되어 담보대출 적격여부\n대출 금액을 즉시 조회하는 B2B 솔루션",
            imageUrl: "/images/service_logo_blitz.png",
            linkUrl: "https://www.instagram.com/blitz_ai",
          },
          {
            title: "닥집 Doczip",
            body: "부동산 정보를 자동으로 조회하고,\n부동산 거래 과정에 필요한 모든 문서를\n자동으로 완성해주는 서비스",
            imageUrl: "/images/service_logo_doczip.png",
            linkUrl: "https://doczip.kr",
          },
          // {
          //   title: "집집 Zipzip",
          //   body: "놓치기 쉬운 급매물, 빠르고 안전하게!\n거래 유형, 예산, 지역 등 입력 조건에 따라\n최적의 급매물을 추천하는 서비스",
          //   imageUrl: "/images/service_logo_zipzip.png",
          // },
          // {
          //   title: "마을멤버십",
          //   body: "직장인을 위한 전대차 모델은 거래 참여자들\n모두의 염원을 담고 있습니다. 임대인-회사-\n근로자 모두를 위한 서비스",
          //   imageUrl: "/images/service_logo_maeul.png",
          // },
        ]}
      />
      <Awards
        title="탱커 수상내역"
        subTitle="매년 공신력 있는 핀테크·프롭테크 관련 대회에서 우수한 성적을 거두며 사업성을 인정받고 있습니다."
        annualAwards={[
          {
            year: "2021",
            awards: [{ award: "2021 매경 핀테크 어워드 우수상" }, { award: "제 1회 Asia F&I PROPTECH GAMECHANGER 공모전 장려상" }, { award: "2021 호반혁신기술공모전 장려상" }],
          },
          {
            year: "2020",
            awards: [
              { award: "2020 부동산서비스산업 창업경진대회 한국감정원장상" },
              { award: "2020 청년 스타트업 어워즈 우수상" },
              {
                award: "2020 데이터스타즈(DATA-Stars) 한국데이터산업진흥원원장상",
              },
              { award: "2020 서울금융위크 IR 컴피티션 서울특별시장상 대상" },
              { award: "핀테크 유공 포상 과학기술정보통신부장관 표창" },
              { award: "벤처·창업진흥 유공 포상 중소벤처기업부장관 표창" },
            ],
          },
          {
            year: "2019",
            awards: [
              { award: "2019 부동산서비스산업 창업경진대회 한국감정원장상" },
              { award: "산업지능화 스타트업 경진대회 광주과학기술원총장상" },
              {
                award: "ICT 혁신기술 멘토링 프로그램 과학기술정보통신부장관상",
              },
              { award: "한국데이터산업진흥원 데이터바우처사업 우수기업 선정" },
            ],
          },
        ]}
      />
      <Pushed imageUrl="/images/side_background_1.png">
        <Product
          title={"부동산 문서,\n조회부터 거래까지 한 번에"}
          subTitle={"1분이면 충분해요!\n주소 입력 한 번으로 물건의 요약 정보,\n공적 장부, 거래 문서를 한 곳에서 확인하세요."}
          mobileImageUrl="/images/side_background_1.png"
          mobileImageRight
        >
          <IconList
            items={[
              {
                imageUrl: "/images/icon_chat.png",
                icon: "filter",
                text: "어려운 공적 장부도 요약해서",
              },
              {
                imageUrl: "/images/icon_char_growth.png",
                icon: "magic",
                text: "실수하기 쉬운 거래 문서도 자동으로",
              },
              {
                imageUrl: "/images/icon_storage.png",
                icon: "coffee",
                text: "공인인증서, 보안 프로그램 설치 없이 안전하게",
              },
            ]}
          />
        </Product>
      </Pushed>
      {/* <Pushed imageUrl="/images/side_background_2.png" reversed> */}
      {/*   <Product */}
      {/*     title={"내 조건에 딱 맞는\n갭매물, 급매물\n찾고 계신가요?"} */}
      {/*     subTitle={"예산, 거래 유형, 지역\n그 이상의 조건에 맞는 매물을 더 간편하게 만나보세요.\n안전한 매물은 물론, 아낄 수 있는 금액까지 한번에 확인하세요."} */}
      {/*     mobileImageUrl="/images/side_background_2.png" */}
      {/*     mobileImageLeft */}
      {/*   > */}
      {/*     <ProductButton title="갭파인더" subTitle="갭투자 가능한 매물 찾을땐" href="https://gapfinder.kr/" target="_blank" imageUrl="/images/service_logo_gapfinder.png" /> */}
      {/*     <ProductButton title="집집" subTitle="조건에 맞는 급매물 검색할 땐" href="https://zipzip.kr/" target="_blank" imageUrl="/images/service_logo_zipzip.png" /> */}
      {/*   </Product> */}
      {/* </Pushed> */}
      <Hiring title="채용 중인 포지션" subTitle={"탱커와 함께할 인재를 찾습니다.\n나이, 연차, 경력과 상관없이 모든 구성원이 탱커의 주인입니다."}>
        <Button href="/hiring" fixedWidth>
          더 알아보기
        </Button>
      </Hiring>
      <Contact title="앞으로의 탱커가 궁금하신가요?" subTitle={"기술과 부동산이 만나 새로운 세상을 만들어갑니다.\nAI 프롭테크 기업 탱커가 궁금하다면 문의주세요."}>
        <Button href="/link/contact" target="_blank" fixedWidth>
          문의하기
        </Button>
      </Contact>
    </LandingBox>
  );
}
