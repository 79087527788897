import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const ServicesBox = styled.div`
  padding: 6rem 1rem 12.5rem 1rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding: 4rem 1rem 5rem 1rem;

    border: 0;
  }
`;

const Title = styled.div`
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.25rem;
  }
`;

const SubTitle = styled.div`
  padding-top: 1.6rem;
  padding-bottom: 3.5rem;

  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.67em;
  text-align: center;
  color: ${colors.gray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 2.5rem;

    font-size: 0.8125rem;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1475px) {
    flex-flow: row wrap;
  }
`;

const Card = styled.a`
  position: relative;

  flex: 0 0 auto;
  display: block;
  padding: 1.875rem 1.5rem 1.875rem 7.75rem;
  margin: 1.25rem 1.25rem;

  border-radius: 10px;
  background-color: ${colors.lighterGray};

  width: 100%;
  max-width: 25rem;
  min-height: 9.875rem;

  transition: all 0.5s ease;

  &:hover {
    background-color: ${colors.lightGray};
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding: 1.2rem 1.2rem 1.2rem 5.25rem;

    max-width: 20rem;
    min-height: 7.5rem;
  }
`;

const CardTitle = styled.div`
  padding-bottom: 0.5rem;

  font-size: 1.25rem;
  font-weight: bold;
  color: ${colors.black};

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 0.6rem;

    font-size: 1rem;
  }
`;

const CardBody = styled.div`
  width: 15.875rem;
  font-size: 0.875rem;
  letter-spacing: -1px;
  line-height: 1.43em;
  color: ${colors.gray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.8125rem;
  }
`;

type CardImageProps = {
  src: string;
};

const CardImage = styled.div<CardImageProps>`
  position: absolute;
  top: calc(4.875rem / 2);
  left: 1.6875rem;

  background-color: ${colors.white};
  background-repeat: no-repeat;
  background-size: 40% 40%;
  background-position: center;
  background-image: url("${(props) => props.src}");

  border-radius: 50%;

  width: 5rem;
  height: 5rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    top: calc(4.5rem / 2);
    left: 1.2rem;

    width: 3rem;
    height: 3rem;
  }
`;

type Service = {
  title: string;
  body: string;
  imageUrl: string;
  linkUrl: string;
};

type Props = {
  title: string;
  subTitle: string;
  services: Service[];
};

export default function Services(props: Props) {
  const cards = props.services.map((service, index) => {
    return (
      <Card key={index} href={service.linkUrl} target="_blank">
        <CardImage src={service.imageUrl} />
        <CardTitle>{service.title}</CardTitle>
        <CardBody>{service.body}</CardBody>
      </Card>
    );
  });

  return (
    <ServicesBox>
      <Title id="services">{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
      <CardsWrapper>{cards}</CardsWrapper>
    </ServicesBox>
  );
}
