import React from "react";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  width: 100%;
  min-height: 10rem;
`;

const SpinnerBox = styled.div`
  flex: 0 0 auto;

  font-size: 1.25rem;
  text-align: center;
`;

type Props = {};

export default function Spinner(props: Props) {
  return (
    <SpinnerWrapper>
      <SpinnerBox>
        <i className="fa fa-circle-o-notch fa-spin" />
      </SpinnerBox>
    </SpinnerWrapper>
  );
}
