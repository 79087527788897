import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const ProductBox = styled.div`
  padding: 1rem 0;
`;

const Title = styled.div`
  padding: 0 1rem;

  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -1px;
  font-weight: bold;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.tabletMax}) {
    font-size: 2.25rem;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    text-align: center;
    font-size: 1.5rem;
    text-align: start;
  }
`;

const SubTitle = styled.div`
  padding: 0 1rem;

  padding-top: 1.5rem;
  padding-bottom: 3.5rem;

  font-size: 1.125rem;
  line-height: 1.67em;
  color: ${colors.darkGray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.tabletMax}) {
    font-size: 1rem;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    text-align: center;
    font-size: 0.8125rem;
    text-align: start;
    letter-spacing: -1px;
  }
`;

type MobileImageProps = {
  right?: boolean;
  left?: boolean;
};

const MobileImage = styled.img<MobileImageProps>`
  position: relative;
  margin: 1.5rem auto;

  ${(props) => (props.right ? "left: 1rem;" : "")}
  ${(props) => (props.left ? "left: -1rem;" : "")}
  width: 100%;
  height: auto;

  @media screen and (min-width: ${lengths.mobileMax}) {
    display: none;
  }
`;

const ChildrenWrapper = styled.div`
  padding: 0 1rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding: 0;
  }
`;

type Props = React.PropsWithChildren<{
  title: string;
  subTitle: string;
  mobileImageUrl?: string;
  mobileImageLeft?: boolean;
  mobileImageRight?: boolean;
}>;

export default function Product(props: Props) {
  return (
    <ProductBox>
      <Title>{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
      <MobileImage src={props.mobileImageUrl} left={props.mobileImageLeft} right={props.mobileImageRight} />
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
    </ProductBox>
  );
}
