import React, { useState } from "react";
import styled from "styled-components";
import { lengths } from "../../values";
import CardFilter from "./CardFilter";
import CardItem from "./CardItem";
import { HiringItemType, RecruitType } from "./type";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 790px;

  @media screen and (max-width: ${lengths.mobileMax}) {
    width: 100%;
  }
`;

const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  padding: 4rem;
  font-size: 20px;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 14px;
    padding: 2rem;
  }
`;

type Props = {
  list: HiringItemType[];
};

export default function CardList({ list }: Props) {
  const [type, setType] = useState<RecruitType | null>(null);

  const FilterList = [
    {
      title: "전체",
      key: [],
      handleClick: () => {
        setType(null);
      },
    },
    {
      title: "상시",
      key: [],
      handleClick: () => {
        setType("상시");
      },
    },
    {
      title: "수시",
      key: [],
      handleClick: () => {
        setType("수시");
      },
    },
  ];

  const filterdCardItems = list.filter((cardItem) => (!type ? true : cardItem.recruitType === type));

  return (
    <Container>
      <CardFilter options={FilterList} defaultOption={FilterList[0]} />
      {filterdCardItems.length > 0 ? (
        filterdCardItems.map((filteredCardItem, index) => (
          <CardItem
            key={index}
            title={filteredCardItem.title}
            contractType={filteredCardItem.contractType}
            description={filteredCardItem.description}
            href={filteredCardItem.href}
          />
        ))
      ) : (
        <EmptyText>진행 중인 공고가 없습니다.</EmptyText>
      )}
    </Container>
  );
}
