import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, lengths } from "../values";
import Button from "./Button";

const FixedBox = styled.div`
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;

  z-index: 600;
`;

const HeaderRow = styled.header`
  width: 100%;
  height: 9.5rem;
  transition: all 0.5s ease;

  @media screen and (max-width: ${lengths.tabletMax}) {
    height: 5rem;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    height: 3.75rem;
  }

  &.scrolled {
    background-color: ${colors.white}cc;
    height: 5rem;

    @media screen and (max-width: ${lengths.mobileMax}) {
      height: 3.75rem;
    }
  }
`;

const SlotsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  width: 100%;
  max-width: ${lengths.containerWidth};
`;

const Slot = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex: 0 0 auto;

  &.from-desktop {
    @media screen and (max-width: ${lengths.tabletMax}) {
      display: none;
    }
  }

  &.from-tablet {
    @media screen and (max-width: ${lengths.mobileMax}) {
      display: none;
    }
  }
`;

const Logo = styled.img`
  width: 10rem;
  height: auto;

  @media screen and (max-width: ${lengths.mobileMax}) {
    width: 7.5rem;
  }
`;

const FlatLink = styled.a`
  display: block;
  background-color: transparent;
  border-radius: 5px;

  padding: 1rem 1.5rem;
  margin-right: 1.5rem;
  cursor: pointer;

  font-size: 1.125rem;
  color: #172141;

  transition: all 0.5s ease;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: ${colors.gray}22;
  }
`;

type State = {
  scrolled: boolean;
};

export default class Header extends React.Component {
  state: State = {
    scrolled: false,
  };

  handleScrollTo = (eid: string) => {
    const element = document.querySelector(`#${eid}`);
    if (element) {
      if (eid === "contact") {
        element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      } else {
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  handleScroll = () => {
    const top = window.scrollY;
    if (!this.state.scrolled && top > lengths.scrollThreshold) {
      this.setState({ scrolled: true });
    } else if (this.state.scrolled && top <= lengths.scrollThreshold) {
      this.setState({ scrolled: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { scrolled } = this.state;

    return (
      <FixedBox>
        <HeaderRow className={scrolled ? "scrolled" : ""}>
          <SlotsWrapper>
            <Slot>
              <Link to="/">
                <Logo src="/images/logo.png" />
              </Link>
            </Slot>
            {window.location.pathname === "/" && (
              <Slot className="from-tablet">
                <FlatLink onClick={() => this.handleScrollTo("services")}>서비스</FlatLink>
                <FlatLink onClick={() => this.handleScrollTo("hiring")}>채용</FlatLink>
                <FlatLink onClick={() => this.handleScrollTo("contact")}>문의</FlatLink>
              </Slot>
            )}
            <Slot className="from-desktop">
              <Button href="/link/brochure" target="_blank">
                회사 소개서 다운로드
              </Button>
            </Slot>
          </SlotsWrapper>
        </HeaderRow>
      </FixedBox>
    );
  }
}
