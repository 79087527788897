import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const HeroBox = styled.div`
  background-color: ${colors.lighterGray};
  background-image: url("/images/hero_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  padding-top: 15rem;
  padding-bottom: max(32vw, 28rem);

  @media screen and (max-width: ${lengths.mobileMax}) {
    background-size: 120% auto;

    padding-top: 7.25rem;
    padding-bottom: 45vw;
  }
`;

const Title = styled.div`
  font-size: 3.375rem;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.div`
  padding-top: 1.6rem;
  padding-bottom: 1.5rem;

  font-size: 1.125rem;
  line-height: 1.67em;
  text-align: center;
  color: ${colors.gray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 2rem;

    font-size: 0.875rem;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const ChildrenBox = styled.div`
  flex: 0 0 auto;
`;

type Props = React.PropsWithChildren<{
  title: string;
  subTitle: string;
}>;

export default function Hero(props: Props) {
  return (
    <HeroBox>
      <Title id="hero">{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
      <ChildrenWrapper>
        <ChildrenBox>{props.children}</ChildrenBox>
      </ChildrenWrapper>
    </HeroBox>
  );
}
