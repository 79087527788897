import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const ContactBox = styled.div`
  background-image: url("/images/contact_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  padding-top: 16.5rem;
  padding-bottom: 16.8rem;
`;

const Title = styled.div`
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  color: ${colors.white};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.div`
  padding-top: 1.9rem;
  padding-bottom: 3.75rem;

  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.67em;
  text-align: center;
  color: ${colors.white};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 2.5rem;

    font-size: 0.875rem;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const ChildrenBox = styled.div`
  flex: 0 0 auto;
`;

type Props = React.PropsWithChildren<{
  title: string;
  subTitle: string;
}>;

export default function Contact(props: Props) {
  return (
    <ContactBox>
      <Title id="contact">{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
      <ChildrenWrapper>
        <ChildrenBox>{props.children}</ChildrenBox>
      </ChildrenWrapper>
    </ContactBox>
  );
}
