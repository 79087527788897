import React from "react";
import styled from "styled-components";
import { lengths } from "../values";
import CardList from "./hiring/CardList";
import { HiringItemType } from "./hiring/type";

const Container = styled.div`
  margin: 0;

  width: 100%;
  min-height: 100vh;
`;

const HiringContainer = styled.div`
  padding-top: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2.75rem;
  padding-bottom: 4rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.5rem;
  }
`;

const cardItems: HiringItemType[] = [
  {
    title: "머신러닝 엔지니어 (ML Engineer)",
    contractType: "정규직",
    recruitType: "상시",
    description:
      "Blitz, 닥집 및 기타 서비스 전반의 구현을 위해 각종 ML 기술 을 활용해 문제를 해결합니다. 각종 사용자 대상 서비스를 위한 ML 모델의 개발 및 유지보수 경험이 있으신 분을 찾습니다.",
    href: "ml",
  },
  {
    title: "백엔드 개발자 (Back-end Developer)",
    contractType: "정규직",
    recruitType: "상시",
    description: "각종 부동산 관련 데이터를 다루는 고가용성의 확장 가능한 시스템을 설계하고 운영합니다. Typed Python 사용에 익숙하고 도메인 모델링 경험이 풍부한 분을 찾습니다.",
    href: "backend",
  },
  {
    title: "프론트엔드 개발자 (Front-end Developer)",
    contractType: "정규직",
    recruitType: "상시",
    description: "Blitz, 닥집 및 기타 서비스 전반의 사용자 대상 웹 프론트엔드를 개발합니다. TypeScript, React.js 사용 경험이 있으며 Pure JS, CSS에 대한 이해가 있는 분을 찾습니다.",
    href: "frontend",
  },
];

export default function Hiring() {
  return (
    <Container>
      <HiringContainer>
        <Title>탱커와 함께할 동료를 기다립니다.</Title>
        <CardList list={cardItems} />
      </HiringContainer>
    </Container>
  );
}
