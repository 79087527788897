import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const Title = styled.div`
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.25rem;
  }
`;

const SubTitle = styled.div`
  padding-top: 1.6rem;
  padding-bottom: 3.5rem;

  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.67em;
  text-align: center;
  color: ${colors.darkGray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 2.5rem;
    font-size: 0.8125rem;
    width: 20.625rem;
    margin: 0 auto;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  padding: 3.875rem 0;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding: 1.5rem 0;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 3.75rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 1.5rem;
    min-width: 22rem;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${lengths.mobileMax}) {
    flex-direction: column;
    width: 21.875rem;
  }
`;

const CardYear = styled.span`
  font-size: 1.25rem;
  color: #1d293f;
  padding-right: 12.5rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-right: 0;
    padding-bottom: 1.5rem;
    font-size: 0.875rem;
  }
`;

const AwardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 33.75rem;
  height: auto;

  @media screen and (max-width: ${lengths.mobileMax}) {
    min-width: 0rem;
    width: auto;
  }
`;

const Award = styled.span`
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #1d293f;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.8125rem;
  }
`;

type Award = {
  award: string;
};

type AnnualAward = {
  year: string;
  awards: Award[];
};

type Props = {
  title: string;
  subTitle: string;
  annualAwards: AnnualAward[];
};

function Awards(props: Props) {
  const cards = props.annualAwards.map((annualAwards, index) => {
    return (
      <Cards key={index}>
        {annualAwards.awards.length > 0 ? (
          <Card>
            <CardYear>{annualAwards.year}</CardYear>
            <AwardsContainer>
              {annualAwards.awards.map((award, index) => {
                return <Award key={index}>{award.award}</Award>;
              })}
            </AwardsContainer>
          </Card>
        ) : null}
      </Cards>
    );
  });

  return (
    <div>
      <Title id="AnnualAwards">{props.title}</Title>
      <SubTitle>{props.subTitle}</SubTitle>
      <CardsWrapper>{cards.length > 0 && cards}</CardsWrapper>
    </div>
  );
}

export default Awards;
