import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../values";
import Container from "./Container";

const FooterBox = styled.footer`
  background-color: ${colors.darkBlue};

  width: 100%;
`;

const SlotsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: start;

  padding: 6.875rem 1rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    flex-flow: column nowrap;
    justify-content: start;
    align-items: start;

    padding: 4.8rem 1rem;
  }
`;

const Slot = styled.div`
  flex: 0 0 auto;

  @media screen and (max-width: ${lengths.mobileMax}) {
    margin-bottom: 2rem;
  }
`;

const Logo = styled.img`
  width: 11rem;
  height: auto;

  @media screen and (max-width: ${lengths.mobileMax}) {
    width: 8rem;
  }
`;

const Title = styled.div`
  padding-top: 1rem;

  font-size: 1.125rem;
  font-weight: bold;
  color: ${colors.white};

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.875rem;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.75em;
  color: ${colors.lightBlue};

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.8125rem;

    &:not(:first-of-type) {
      margin-top: 6px;
    }
  }
`;

const ContactText = styled.div`
  padding-top: 1rem;

  font-size: 1rem;
  font-weight: bold;
  line-height: 1.75em;
  color: ${colors.lightYellow};

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.8125rem;
  }
`;

const FlatLink = styled.a`
  display: block;
  background-color: transparent;
  border-radius: 5px;

  padding: 0.75rem 1.5rem;
  margin-bottom: 0.5rem;

  font-size: 1rem;
  font-weight: bold;
  color: ${colors.lightBlue};

  transition: all 0.5s ease;

  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: ${colors.white}22;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    border: 0;

    padding: 0.5rem 0;
    margin-bottom: 0.25rem;

    font-size: 0.8125rem;
    font-weight: normal;
    color: ${colors.lightGray};

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;

export default function Footer() {
  return (
    <FooterBox>
      <Container>
        <SlotsWrapper>
          <Slot>
            <Logo src="/images/logo_white.png" />
            <Title>탱커 주식회사</Title>
          </Slot>
          <Slot>
            <Text>
              대표: 임현서
              <br />
              사업자등록번호: 558-86-00202
            </Text>
            <Text>
              여의도 본사:
              <br />
              서울특별시 영등포구 국회대로 70길 15-1, 801호
              <br />
              (여의도동, 극동브이아이피빌딩)
            </Text>
            <Text>
              송파 지점:
              <br />
              서울특별시 송파구 중대로 135, 10층
              <br />
              (가락동, IT 벤처타워 한국인터넷진흥원)
            </Text>
            <ContactText>
              문의전화: 02-514-1008 / FAX: 050-8090-1008
              <br />
              Email: ask@tanker.fund
            </ContactText>
          </Slot>
          <Slot>
            <FlatLink>이용약관</FlatLink>
            <FlatLink>개인정보취급방침</FlatLink>
            {/* <FlatLink href="/link/contact" target="_blank">
              문의하기
            </FlatLink> */}
          </Slot>
        </SlotsWrapper>
      </Container>
    </FooterBox>
  );
}
