import Axios from "axios";
import config from "../config";

const urlBase = "https://sheets.googleapis.com/v4/spreadsheets";

export type HiringPosition = {
  title: string;
  subTitle: string;
  description: string;
};

export type Store = {
  hiring: HiringPosition[];
  redirect: Record<string, string>;
};

let fetched = false;
const store: Store = {
  hiring: [],
  redirect: {},
};

async function fetchSheet(sheetName: string, range: string) {
  const url = `${urlBase}/${config.dataSheetId}/values/${sheetName}!${range}`;
  const search = `key=${config.fetchKey}`;
  const response = await Axios.get(`${url}?${search}`);
  return response.data.values as string[];
}

export async function fetchHiring() {
  const rows = await fetchSheet("Hiring", "A2:C");
  if (!rows) {
    return;
  }
  store.hiring = rows.map((row) => ({
    title: row[0],
    subTitle: row[1],
    description: row[2],
  })) as HiringPosition[];
}

export async function fetchRedirect() {
  const rows = await fetchSheet("Redirect", "A2:B");
  if (!rows) {
    return;
  }
  store.redirect = {};
  rows.forEach((row) => {
    store.redirect[row[0].trim()] = row[1];
  });
}

export async function fetchAll() {
  if (!fetched) {
    await Promise.all([fetchHiring(), fetchRedirect()]);
    fetched = true;
  }
}

export async function get<T extends keyof Store>(key: T): Promise<Store[T]> {
  await fetchAll();
  return store[key];
}

export type PageInfo = {
  title?: string;
  id: string;
};

export async function fetchNotionPage(pageInfo?: PageInfo) {
  try {
    if (!pageInfo) {
      throw new Error("no data");
    }
    if (process.env.NODE_ENV === "development") {
      const response = await fetch(`/v1/page/${pageInfo.id}`);
      const data = await response.json();
      return data;
    } else {
      const response = await fetch(`https://notion-api.splitbee.io/v1/page/${pageInfo.id}`);
      const data = await response.json();
      return data;
    }
  } catch (error) {
    // eslint-disable-next-line
    console.error("Error fetching data:", error);
    throw error;
  }
}
