import React from "react";
import { Route, Routes } from "react-router-dom";
import Hiring from "./components/Hiring";
import HiringDetail from "./components/HiringDetail";
import Landing from "./components/Landing";
import NotFound from "./components/NotFound";

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/hiring" element={<Hiring />} />
      <Route path="/hiring/:hiringId" element={<HiringDetail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouter;
