import React, { useEffect, useState } from "react";
import { BlockMapType, NotionRenderer } from "react-notion";
import { PageInfo, fetchNotionPage } from "../utils/data";
import LoadingCircle from "./LoadingCircle";
import NotFound from "./NotFound";

type Props = {
  pageInfo?: PageInfo;
};

export default function NotionPageContainer({ pageInfo }: Props) {
  const [blockMap, setBlockMap] = useState<BlockMapType | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchNotionPage(pageInfo)
      .then((result) => setBlockMap(result))
      .catch(() => {
        setError(true);
      });
  }, [pageInfo]);

  const getContent = () => {
    if (error) {
      return <NotFound />;
    }

    return (
      <div>
        {blockMap && pageInfo ? (
          <>
            {pageInfo.title && <h1>{pageInfo.title}</h1>}
            <NotionRenderer blockMap={blockMap} />
          </>
        ) : (
          <LoadingCircle />
        )}
      </div>
    );
  };

  return <>{getContent()}</>;
}
