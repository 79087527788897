import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const IconListBox = styled.div`
  padding: 0;
`;

const Item = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;

  padding-bottom: 1.2rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    justify-content: center;

    padding-bottom: 0.8rem;
  }

  @media screen and (max-width: 28rem) {
    justify-content: start;
  }
`;

const ItemIcon = styled.div`
  flex: 0 0 auto;
  margin-right: 1.1rem;

  background-color: ${colors.lighterGray};

  border-radius: 50%;

  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.5rem;
  text-align: center;
  line-height: 3.75rem;
  color: ${colors.blue};

  @media screen and (max-width: ${lengths.tabletMax}) {
    margin-right: 0.8rem;

    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1rem;
    line-height: 2.25rem;
    margin-right: 0.5rem;
  }
`;

const ItemText = styled.div`
  flex: 0 0 auto;

  font-size: 1.5rem;
  font-weight: bold;

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.tabletMax}) {
    font-size: 1rem;
  }

  @media screen and (max-width: ${lengths.mobileMax}) {
    min-width: 20rem;

    font-size: 0.875rem;
  }

  @media screen and (max-width: 28rem) {
    min-width: 0;
  }
`;

const IconImage = styled.img`
  width: 1.1875rem;
  height: 1.1875rem;
`;

type Item = {
  icon: string;
  imageUrl?: string;
  text: string;
};

type Props = {
  items: Item[];
};

export default function IconList(props: Props) {
  const items = props.items.map((item, index) => {
    return (
      <Item key={index}>
        <ItemIcon>{item.imageUrl ? <IconImage src={item.imageUrl} /> : <i className={`fa fa-${item.icon}`} />}</ItemIcon>
        <ItemText>{item.text}</ItemText>
      </Item>
    );
  });
  return <IconListBox>{items}</IconListBox>;
}
