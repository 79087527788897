import React from "react";
import styled, { keyframes } from "styled-components";

interface LoadingCircleProps {
  size?: number;
  color?: string;
}

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LoadingCircleDiv = styled.div<{ size: number; color: string }>`
  display: inline-block;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid ${({ color }) => color};
  animation: ${spin} 1s linear infinite;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export default function LoadingCircle({ size = 40, color = "#3498db" }: LoadingCircleProps) {
  return (
    <LoadingCircleContainer>
      <LoadingCircleDiv size={size} color={color} />
    </LoadingCircleContainer>
  );
}
