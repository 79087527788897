import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { get, HiringPosition } from "../../utils/data";
import { colors, lengths } from "../../values";
import Container from "../Container";
import Spinner from "../Spinner";

const HiringBox = styled.div`
  background-color: ${colors.lighterGray};
  background-image: url("/images/hiring_background.png");
  background-size: max(20rem, 15vw) auto;
  background-repeat: no-repeat;
  background-position: top right;

  padding: 7.5rem 1rem 11.25rem 1rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    background-size: min(28vw, 20rem) auto;

    padding: 4rem 1rem 5rem 1rem;
  }
`;

const Title = styled.div`
  font-size: 2.75rem;
  line-height: 1.25em;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;

  font-size: 1.125rem;
  line-height: 1.67em;
  text-align: center;
  color: ${colors.gray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-bottom: 2.5rem;

    font-size: 0.875rem;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  flex: 0 0 auto;
  position: relative;
  padding: 1.5rem 1rem;
  margin: 0.9rem 0.9rem;

  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  background-color: ${colors.white};

  width: 100%;
  max-width: 21.875rem;
  min-height: 11.25rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    min-height: 10rem;
  }
`;

const CardTitle = styled.div`
  padding-bottom: 1rem;

  font-size: 1.125rem;
  font-weight: bold;
  color: ${colors.black};

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.875rem;
  }
`;

const CardSubTitle = styled.div`
  padding-bottom: 0.5rem;

  font-size: 0.8125rem;
  line-height: 1.54em;
  font-weight: bold;
  color: ${colors.gray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-weight: normal;
  }
`;

const CardDescription = styled.div`
  font-size: 0.8125rem;
  line-height: 1.54em;
  font-weight: bold;
  color: ${colors.gray};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 0.75rem;

    font-weight: normal;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding-top: 3.75rem;

  @media screen and (max-width: ${lengths.mobileMax}) {
    padding-top: 3rem;
  }
`;

type Props = React.PropsWithChildren<{
  title: string;
  subTitle: string;
}>;

export default function Hiring(props: Props) {
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState<HiringPosition[]>([]);

  useEffect(() => {
    setLoading(true);
    get("hiring").then((value) => {
      setPositions(value);
      setLoading(false);
    });
  }, []);

  const spinner = loading ? <Spinner /> : "";

  const cards = positions.map((position, index) => {
    return (
      <Card key={index}>
        <CardTitle>{position.title}</CardTitle>
        <CardSubTitle>{position.subTitle}</CardSubTitle>
        <CardDescription>{position.description}</CardDescription>
      </Card>
    );
  });

  return (
    <HiringBox>
      <Container>
        <Title id="hiring">{props.title}</Title>
        <SubTitle>{props.subTitle}</SubTitle>
        <CardsWrapper>
          {spinner}
          {cards}
        </CardsWrapper>
        <FooterWrapper>{props.children}</FooterWrapper>
      </Container>
    </HiringBox>
  );
}
