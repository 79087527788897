import React from "react";
import styled from "styled-components";
import { colors, lengths } from "../../values";

const PartnersBox = styled.div`
  padding: 5.5rem 1rem 3rem 1rem;
`;

const Title = styled.div`
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  white-space: pre-wrap;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 1.25rem;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
`;

const Image = styled.img`
  display: block;
  flex: 0 0 auto;
  margin: 0.5rem 2.5rem;

  width: 11.25rem;
  height: auto;

  @media screen and (max-width: ${lengths.mobileMax}) {
    width: 8rem;
    margin: 0.25rem 1rem;
  }
`;

type Props = {
  title: string;
  imageUrls: string[];
};

export default function Partners(props: Props) {
  const images = props.imageUrls.map((url, index) => {
    return <Image src={url} key={index} />;
  });

  return (
    <PartnersBox>
      <Title id="partners">{props.title}</Title>
      <ImagesWrapper>{images}</ImagesWrapper>
    </PartnersBox>
  );
}
