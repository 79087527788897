import React from "react";
import styled from "styled-components";
import { lengths } from "../../values";

const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-bottom: 1px solid #d0d0d0;

  &:last-of-type {
    border-bottom: none;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.span`
  font-size: 20px;
  color: #172141;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 14px;
  }
`;

const Description = styled.span`
  font-size: 16px;
  color: #666666;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 12px;
  }
`;

const Department = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  font-size: 16px;
  color: #666666;

  @media screen and (max-width: ${lengths.mobileMax}) {
    font-size: 13px;
  }
`;

const Card = styled.a`
  display: flex;
  gap: 3rem;
  cursor: pointer;
`;

type Props = {
  title: string;
  contractType: string;
  description: string;
  href: string;
};

export default function CardItem({ title, contractType, description, href }: Props) {
  return (
    <Container>
      <Card href={`/hiring/${href}`}>
        <Department>{contractType}</Department>
        <InfoBox>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </InfoBox>
      </Card>
    </Container>
  );
}
